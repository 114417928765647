<template>
  <div>
    <div class="crumbs">街道管理</div>
    <div class="center">
      <div class="table">
        <div class="center_top">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="选择区域" prop="">
              <el-cascader
                v-model="areaValue"
                :options="options"
                :props="areaProps"
              ></el-cascader>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="searchStreetList"
                >查询</el-button
              >
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                size="medium"
                @click="streetShowToggle(true, true)"
                >添加街道</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#F5F7FA', 'text-align': 'center' }"
        >
          <el-table-column
            prop="street_id"
            label="street_id"
            align="center"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="info.province_name"
            label="所在省"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="info.city_name" label="所在市" align="center">
          </el-table-column>
          <el-table-column prop="info.area_name" label="所在区" align="center">
          </el-table-column>
          <el-table-column prop="street_name" label="街道名称" align="center">
          </el-table-column>

          <el-table-column label="操作" :align="'center'">
            <template slot-scope="scope">
              <el-button type="text" @click="editStreetEvent(scope.row)"
                >编辑街道</el-button
              >
              <!-- <el-button type="text" @click="AssignStreet(scope.row.user_id)">分配街道</el-button> -->
              <el-button
                type="text"
                style="color: red"
                @click="delStreetEvent(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div>
        <span style="float: right; margin-top: 20px; margin-right: 20px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="1"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="counts"
            background
          >
          </el-pagination>
        </span>
      </div>
    </div>

    <el-dialog
      :title="this.isAdd ? '添加街道' : '编辑街道'"
      :visible.sync="addStreetShow"
      width="30%"
    >
      <AreaSelect ref="_Area" street_type="input" :areadata="areaData" />

      <span slot="footer" class="dialog-footer">
        <el-button @click="streetShowToggle(false, false)">取 消</el-button>
        <el-button type="primary" @click="handleStreetEvent">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetStreetList,
  getProvince,
  getCity,
  getDistrict,
  addStreet,
  streetDelete,
  streetEdit,
} from "../request/api";
import AreaSelect from "../components/AreaSelect/AreaSelect.vue";
export default {
  name: "StreetList",

  data() {
    return {
      areaValue: [],
      options: [],
      areaProps: {
        lazy: true,
        async lazyLoad(node, resolve) {
          const { level, value } = node;
          console.log("data", node);
          console.log("level", level);
          if (level == 0) {
            const { data } = await getProvince();
            const nodes = [];

            data.forEach((item) => {
              nodes.push({
                value: item.provinceid,
                label: item.province,
                leaf: level >= 2,
              });
            });
            resolve(nodes);
          }

          if (level == 1) {
            const { data } = await getCity({
              province_id: value,
            });
            const nodes = [];

            data.forEach((item) => {
              nodes.push({
                value: item.cityid,
                label: item.city,
                leaf: level >= 2,
              });
            });

            resolve(nodes);
          }

          if (level == 2) {
            const { data } = await getDistrict({
              city_id: value,
            });
            const nodes = [];

            data.forEach((item) => {
              nodes.push({
                value: item.areaid,
                label: item.area,
                leaf: level >= 2,
              });
            });

            resolve(nodes);
          }
        },
      },
      tableData: [],
      addStreetShow: false,

      isAdd: false,

      // 街道
      StreetList: [],

      areaData: {
        province: "",
        city: "",
        area: "",
        street: "",
        street_id: null,
      },
      counts: 0, //总数
      page: 1, //当前页
      limit: 10, //一页展示几条
    };
  },
  methods: {
    streetShowToggle(statu = false, isAdd = false) {
      this.addStreetShow = statu;
      this.isAdd = isAdd;

      if (statu) {
        this.areaValue = [];
      }

      if (!statu && !isAdd && this.street_id != "") {
        this.areaData.province = "";
        this.areaData.city = "";
        this.areaData.area = "";
        this.areaData.street = "";
        this.areaData.street_id = null;
      }
    },
    async searchStreetList() {
      if (this.areaValue.length < 3) {
        this.$message.error("请先选择区域!");
        return;
      }

      const { data } = await GetStreetList({
        district_id: this.areaValue[2],
        is_all: 1,
      });

      this.tableData = data.list;
    },
    async GetStreetListData() {
      const { data } = await GetStreetList({
        page: this.page,
        limit: this.limit,
        is_all: 0,
      });

      this.tableData = data.list;
      this.counts = data.count;
    },

    async handleStreetEvent() {
      console.log(this.$refs);
      console.log(this.$refs._Area.areaIds);
      const _fc = this.isAdd ? addStreet : streetEdit;

      const areaIds = this.$refs._Area.areaIds;

      if (this.isAdd) {
        for (const key in areaIds) {
          if (
            (areaIds[key] == null || areaIds[key] == undefined) &&
            key != "street_name" &&
            key != "street_id"
          ) {
            this.$message.error("请先选择区域!");
            return;
          } else if (key == "street_name" && areaIds[key] == "") {
            this.$message.error("请填写街道名称!");
            return;
          }
        }
      } else {
        for (const key in areaIds) {
          if (key != "street_name" && isNaN(areaIds[key])) {
            this.$message.error("请先选择区域!");
            return;
          } else if (key == "street_name" && areaIds[key] == "") {
            this.$message.error("请填写街道名称!");
            return;
          }
        }
      }

      const params = {
        province_id: areaIds.province_id,
        city_id: areaIds.city_id,
        district_id: areaIds.area_id,
        street_name: areaIds.street_name,
      };

      if (!this.isAdd) {
        params.street_id = areaIds.street_id;
      }

      const res = await _fc(params);

      if (res.code == 1) {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.streetShowToggle(false, false);
        this.GetStreetListData();
      } else {
        this.$message.error("请先检查填写内容!");
      }
    },
    editStreetEvent(row) {
      console.log("row----", row);

      // this.street_id = row.street_id
      // this.street_name = row.street_name

      this.areaData.province = row.info.province_name;
      this.areaData.city = row.info.city_name;
      this.areaData.area = row.info.area_name;
      this.areaData.street = row.street_name;
      this.areaData.street_id = row.street_id;
      this.streetShowToggle(true, false);
    },
    async delStreetEvent(row) {
      this.$confirm("此操作将永久删除该街道, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const res = await streetDelete({
          street_id: row.street_id,
        });

        if (res.code == 1) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.GetStreetListData();
        } else {
          this.$message.error("删除失败，稍后重试!");
          this.GetStreetListData();
        }
      });
    },
    handleSizeChange(val) {
      this.limit = val;
      this.GetStreetListData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.GetStreetListData();
    },
  },
  components: {
    AreaSelect,
  },
  async mounted() {
    this.GetStreetListData();
  },
};
</script>

<style lang="less" scoped>
.crumbs {
  padding: 5px 20px;
  background-color: rgb(240, 240, 240);
}

.center {
  width: 100vw;
  height: calc(100% - 31px);
  margin: auto;
  overflow: auto;
  box-sizing: border-box;
}

.abc {
  display: flex;
  flex-wrap: wrap;
  margin-left: 25px;
}

::v-deep .el-radio {
  margin: 5px 0;
  margin-right: 20px;
}

.table {
  height: calc(100vh - 100px);
  overflow: auto;
  padding: 0 10px;
}

.center_top {
  padding-top: 10px;
  padding-bottom: 13px;
  height: 36px;
  display: flex;
  justify-content: center;

  ::v-deep .el-input__inner {
    width: 190px;
    height: 32px;
  }
}
</style>
