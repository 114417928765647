<template>
  <el-form :inline="true" class="demo-ruleForm" label-width="100px">
    <!-- <el-form-item label="区域" prop="">
          <el-cascader v-model="areaValue" :options="options" :props="areaProps"></el-cascader>
        </el-form-item> -->

    <el-form-item label="省" prop="">
      <el-select
        v-model="areaIds.province_id"
        placeholder="请选择省份"
        @change="provinceidChange"
      >
        <el-option
          v-for="item in ProvinceList"
          :key="item.provinceid"
          :label="item.province"
          :value="item.provinceid"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="市" prop="">
      <el-select
        v-model="areaIds.city_id"
        placeholder="请先选择省份再选择市"
        @change="cityidChange"
      >
        <el-option
          v-for="item in CityList"
          :key="item.cityid"
          :label="item.city"
          :value="item.cityid"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="区" prop="">
      <el-select
        v-model="areaIds.area_id"
        placeholder="请先选择市再选择区"
        @change="areaidChange"
      >
        <el-option
          v-for="item in DistrictList"
          :key="item.areaid"
          :label="item.area"
          :value="item.areaid"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item
      label="街道"
      prop=""
      width="100%"
      v-if="street_type == 'input'"
    >
      <el-input
        v-model="areaIds.street_name"
        placeholder="请输入街道名称"
      ></el-input>
    </el-form-item>
    <el-form-item label="街道" prop="" v-if="street_type == 'select'">
      <el-select v-model="areaIds.street_id" placeholder="请先选择区再选择街道">
        <el-option
          v-for="item in StreetList"
          :key="item.street_id"
          :label="item.street_name"
          :value="item.street_id"
        >
        </el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
import {
  GetStreetList,
  getProvince,
  getCity,
  getDistrict,
} from "../../request/api";
export default {
  name: "AreaSelect",
  data: () => {
    return {
      // 省
      ProvinceList: [],
      // 市
      CityList: [],
      // 区
      DistrictList: [],
      // 街道
      StreetList: [],
      areaIds: {
        province_id: null,
        city_id: null,
        area_id: null,
        street_id: null,
        street_name: "",
      },
    };
  },
  props: {
    street_type: {
      type: String,
      default: () => {
        return "input";
      },
    },
    areadata: {
      type: Object,
      default: () => {
        return {
          province: "",
          city: "",
          area: "",
          street: "",
        };
      },
    },
  },
  methods: {
    async getProvinceListData() {
      const res = await getProvince();
      if (res.code == 1) {
        this.ProvinceList = res.data;
      }
    },
    async getCityListData(province_id) {
      const res = await getCity({ province_id });
      if (res.code == 1) {
        this.CityList = res.data;
      }
    },
    async getDistrictData(city_id) {
      const res = await getDistrict({ city_id });
      if (res.code == 1) {
        this.DistrictList = res.data;
      }
    },
    async getStreetListData(district_id) {
      const res = await GetStreetList({ district_id });
      if (res.code == 1) {
        this.StreetList = res.data.list;
      }
    },
    provinceidChange(province_id) {
      this.getCityListData(province_id);
      this.areaIds.city_id = null;
      this.areaIds.area_id = null;
      this.street_type == "select" && (this.areaIds.street_id = null);
      this.areaIds.street_name = "";
    },
    cityidChange(cityid) {
      this.getDistrictData(cityid);
      this.areaIds.area_id = null;
      this.street_type == "select" && (this.areaIds.street_id = null);
      this.areaIds.street_name = "";
    },
    areaidChange(district_id) {
      this.street_type == "select" && this.getStreetListData(district_id);
      this.street_type == "select" && (this.areaIds.street_id = null);
      this.areaIds.street_name = "";
    },
  },
  watch: {
    areadata: {
      handler(newV, oldV) {
        this.areaIds.province_id = newV.province;
        this.areaIds.city_id = newV.city;
        this.areaIds.area_id = newV.area;

        this.areaIds.street_name =
          this.street_type == "input" ? newV.street : newV.street_name;
        this.areaIds.street_id =
          this.street_type == "input" ? newV.street_id : newV.street;

        this.CityList = [];
        this.DistrictList = [];
        this.StreetList = [];
      },
      deep: true,
    },
  },
  created() {
    this.getProvinceListData();

    this.areaIds.province_id = this.$props.areadata.province;
    this.areaIds.city_id = this.$props.areadata.city;
    this.areaIds.area_id = this.$props.areadata.area;
    this.areaIds.street_name =
      this.street_type == "input"
        ? this.$props.areadata.street
        : this.$props.areadata.street_name;
    this.areaIds.street_id =
      this.street_type == "input"
        ? this.$props.areadata.street_id
        : this.$props.areadata.street;
  },
};
</script>
